@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakartas:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta");

// FONT FAMILY
$PrimaryFont: "Plus Jakarta", sans-serif;
$SerifFont: "Poppins", sans-serif;
$CodeFont: "Poppins", sans-serif;
$primary-font: unset;

// COLORS
$color-dark: #121313;
$color-darker: #1e2022;
$color-darker-hover: #272a2c;
$color-white: #f4f4f4;
$color-blue: #2860F6;
$color-light-grey: #999;
$color-dark-grey: #555;


// FONT SIZES
$default-font-size: 1rem;

// GRID
$grid-width: 114rem; // 1140px standard width
$gutter-vertical: 4rem; // 40px Gutter Y
$gutter-vertical-small: 3rem; // 30px Gutter Y
$gutter-horizontal: 2rem; // 20px Gutter X

// MEDIA QUERIES
@mixin for-mobile {
    @media (max-width: 767px) { @content; }
  }
  @mixin for-desktop {
    @media (min-width: 767px) { @content; }
  }
  @mixin for-smallest {
    @media (max-width: 320px) { @content; }
  }
 

// BACKGROUND COLORS
$background-lightblue: rgba(40, 96, 246, 0.2);
$background-blue: #2860F6;
$background-dark-blue: #1A2C58;
