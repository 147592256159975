@import "./variables";
@import "./pages/home";
@import "./components/banner";
@import "./components/decision-tree";
@import "./components/footer";
@tailwind base;
@tailwind components;
@tailwind utilities;

.s-app{
    @include for-desktop{
        height: 100vh;
        background-size: cover;
    }
    @media all and (width: 768px) and (height: 1024px) and (orientation:portrait) {
       overflow: hidden;

      
      }
      @media all and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
        overflow: hidden;
 
       }
    @include for-mobile{
        background-image: unset !important;
    }
    &__height{
        @include for-mobile{
           height: 100vh;
        } 
    }
    &__container{
        @include for-desktop{
           
            max-width: 767px;
            margin-right: auto;
            padding-top: 10px;
            margin-left: 80px;
            // padding-left: 20%;
        }

        //IPAD
        @media all and (width: 768px) and (height: 1024px) and (orientation:portrait) {
            margin-left: 0px;
           max-width: 768px;
           padding-top: 0px;
          }

             //IPAD PRO
             @media all and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
                max-width: 1026px;
                margin-left: 0px;
                padding-top: 0px;
              }

        &__start{
            @include for-desktop{
                height: 612px;
          }


          //IPAD PRO
          @media all and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
            height: 1191px;
          }
         
       
          //IPAD 
          @media all and (width: 768px) and (height: 1024px) and (orientation:portrait) {
            height: 848px;
          }


          @media all and (min-width: 1000px) and (max-height: 750px) {
            height: 490px;
            }

            @media all and (min-width: 1300px) and (min-height: 1000px) {
                height: 725px;
                }
           
        }

        &__answer{
       #answer{
        padding-left: 10px;
       }
           @include for-desktop{
            
               #answer{
                max-width: 339px;
               }
            
         }
        }
    }

   
   .absoluteBtn{
       @include for-desktop{
           position: absolute;
           bottom: 20%;
           width: 100%;
       }

       @include for-mobile{
           position: relative;
           bottom: 11%;
           width: 100%;
           left: 0;
           right: 0;
           margin-left: auto;
           margin-right: auto;
       }
   }

   .absoluteMobileBtn{
    @media all and (max-width: 360px) and (max-height: 640px) {        
       
        bottom: 0 !important;
        
    }
}
  
   
} 

.copied {
    border: 1px solid #2FB100;
    color: #2FB100 !important
}

.absoluteDoubleBtn {
    @include for-desktop{
    position: absolute;
    bottom: 15%;
    width: 80%;
}
   }

   nav > div {
       @include for-desktop{
           width: 80%;
           margin-left: auto;
           margin-right: auto
       }
       @include for-mobile{
        width: 90%;
        margin-left: auto;
        margin-right: auto
    }
   }

   .custom-width {
    @include for-desktop{
        width: 80%;
        margin-left: auto;
        margin-right: auto
    }
    
    @include for-mobile{
     width: 90%;
     margin-left: auto;
     margin-right: auto;
     overflow-y: scroll;
 }



   }

   .listHeight{
    @media all and (max-width: 360px) and (max-height: 640px) {
        max-height: 500px !important;
        }
        @media all and (max-width: 400px) and (max-height: 960px) {
            height: 75vh;
            width: 100%;
            }

 }


 .newLogo {
    max-width: 150px;
 }

 .newFrame{
     max-width: 95px;
 }

 *:focus {
    outline: none;
}

.backBtn {
    position: absolute;
    top: -88px;
    right: 0;
    font-size: 16px;
    margin-right: 10%;
    img {
        margin-right: 6px;
        max-width: 20px;
    }

    span{
        color: $background-blue;
        font-weight: 600;
    }
    @include for-mobile{
        top: 30px;
      
    }
}

.restartBtn {
    position: absolute;
    top: -88px;
    font-size: 16px;
    right: 0;
    margin-right: 10%;
    img {
     max-width: 20px;
    }

    span{
        color: $background-blue;
        font-weight: 600;
        width: 105px;
    }

 @include for-mobile{
     top: 30px;
     margin-right: 0px
 }
}

.finalBackBtn{
    position: absolute;
    top: -88px;
    right: 167px;
    font-size: 16px;
    margin-right: 10%;
    img {
        margin-right: 6px;
        max-width: 20px;
        @include for-smallest{
            margin-right: 10px;
        }
        @include for-mobile{
  
            margin: 0px;
        }
    }

    span{
        color: $background-blue;
        font-weight: 600;
        font-size: 16px;
     

        @include for-mobile{
  
            margin-left: 5px;
        }
    }
    @include for-mobile{
        top: 28px;
        right: 41px;
    }

    @include for-smallest{
        right: 21px;
        top: 31px;
    }
}

.finalRestartBtn {
    position: absolute;
    top: -88px;
    font-size: 16px;
    right: 0;
    margin-right: 10%;
    img {
     max-width: 20px;
     margin-right: 5px;
    }

    span{
        color: $background-blue;
        font-weight: 600;
        
        font-size: 16px;
        @include for-mobile{
            display: none;
        }

        @include for-smallest{
            display: none;
        }
    }

 @include for-mobile{
     top: 30px;
     margin-right: 20px
 }
 @include for-smallest{
    top: 33px;
}
}