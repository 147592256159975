.s-decision-tree{
@include for-desktop{
    height: 688px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    position: relative;
}

//IPAD
@media all and (width: 768px) and (height: 1024px) and (orientation:portrait) {
    height: 910px;
  }

     //IPAD PRO
     @media all and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
        height: 1251px;
   
      }


@media all and (min-width: 1000px) and (max-height: 750px) {
    height: 550px;
    }
    @media all and (min-width: 1300px) and (min-height: 1000px) {
        height: 742px;
        }

.tabIndex{
    @include for-desktop{
        overflow-y: scroll;
        height: 680px;
        width: 100% !important;
    padding-left: 10%;
    padding-right: 10%;
      
    }


//IPAD
    @media all and (width: 768px) and (height: 1024px) and (orientation:portrait) {
        height: 888px;
      }
       //IPAD PRO
     @media all and (width: 1024px) and (height: 1366px) and (orientation:portrait) {
        height: 1228px;
      }

    @media all and (min-width: 1300px) and (min-height: 1000px) {
        height: 917px
        }
   
}

    &__progress {
        &__outer{
            width: 100%;
            height: 4px;
            position: relative;
            background: #E9E9E9;
            // border: 1px solid #E9E9E9;
            border-radius: 100px;
            margin-top: 8px;
        }
       

        &__title{
            color: $background-dark-blue;
            font-size: 18px;
            @include for-mobile{
                font-family: inherit !important;
            }
        }

        &__inner{
            transition: 'all 4s ease-out';
            position: absolute;
            width:  75%;
            border-radius: 100px;
            height: 100%;
            background-color: $background-dark-blue !important;
            background: -webkit-linear-gradient(left, #06b025 0%, #06b025 30%, #5faf6c 45%, #c5ddc6 50%, #5faf6c 60%, #06b025 70%, #06b025 100%);
            background: linear-gradient(left, #06b025 0%, #06b025 30%, #5faf6c 45%, #c5ddc6 50%, #5faf6c 60%, #06b025 70%, #06b025 100%);
            background-repeat: no-repeat;
            background-size: 1000px 100%;
            display: inline-block;

            -webkit-animation-duration: 5.25s;
            animation-duration: 5.25s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-name: shimmer;
            animation-name: shimmer;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-transition: width .6s ease;
            transition: width .6s ease;
        }
    }

    &__btn{
        background-color: $background-lightblue;
        color: $color-blue;
        width: 100%;
        padding: 5px;
        font-family: $primary-font;
        border-radius: 4px;
        text-align: left;
        border: 1px solid $color-blue;
        font-size: 22px;

        @include for-desktop {
            padding: 5px;
        }

        @include for-smallest{
            font-size: 16px;
        }
        span {
          
             background: #99b3fa;
             border-radius: 5px;
             font-weight: 500;

             @include for-mobile{
                 padding-left: 10px;
                 padding-right: 10px;
                 width: 49px;
                 font-size: 31px;
                 display: flex;
                 justify-content: center;
             }

             @include for-desktop {
                font-size: 25px;
                padding: 5px;
                width: 50px;
                display: flex;
                justify-content: center;
             }

             @include for-smallest{
                width: 40px;
                    font-size: 24px;
             }
       }
p{
  
        max-width: 250px;
    
}
     
    }
  
    &__btn--final{
        background: white !important;
        color: $color-blue !important;
        width: 100%;
        padding: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        font-family: $primary-font;
        border-radius: 4px;
        text-align: left;
        border: 1px solid $color-blue;

        @include for-desktop{
            padding: 20px;
        }

        span{
            padding: 10px;
             background: #99b3fa;
             border-radius: 5px;

             @include for-desktop{
                font-size: 25px;
                padding: 10px;
                max-width: 50px;
                display: flex;
                justify-content: center;
             }
       }
    }


    &__question{
        
        font-family: $primary-font;
        font-size: 32px;
        color: $background-dark-blue;
        margin-top: 15px;
        line-height: 41.41px;
        font-weight: 700;
        @include for-smallest{
            font-size: 20px !important;
        }
    }

    &__paragraph{
        font-weight: 400;
        font-family: $primary-font;
       
        color: $background-dark-blue;
        @include for-mobile{
            font-family: inherit;
        }
        @include for-smallest{
            font-size: 14px !important;
        }
    }

    &__link{
        font-weight: 600;
        font-family: $primary-font;
        font-size: 20px;
        color: $background-blue;
        // text-decoration: none;
        cursor: pointer;

        &--dark{
            color: #152C5B;
            font-weight: 600;
           
        }
    }


    &__container{
        padding: 20px;
        border: 1px solid #E0E0E0;
        border-radius: 3px;

        &__title{
            font-weight: 600;
            text-decoration: underline;
            color: $background-blue;
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 12px;
            @include for-mobile{
                font-family: inherit !important;
            }
        }
        &__subtitle{
            font-weight: 400;
            font-size: 16px;
            color: $background-dark-blue;

        }
        &__paragraph{
            margin-top: 32px;
            color: #8A95AD;
            font-size: 16px;
        }

        button, .btnLink{
            background: $background-blue;
            color: white;
            font-weight: 900;
            font-size: 18px;
            text-align: center;

            @include for-desktop{
                padding-left: 20px;

    width: 280px;

    padding: 15px;
    display: flex;
    justify-content: center;
    padding-right: 20px;
            }
        }

        
    }
    

    &__btn--back{
        border: 1px solid $background-blue;
        color: $background-blue;
        padding: 10px;
        font-weight: 600;
        border-radius: 2px;
        height: 64px;
        width: 141px;
        @include for-smallest{
            width: 100px;
            height: 50px;
        }
    }

    &__btn--next{
        background-color:$background-blue;
        color: white;
        width: 141px;
        height: 64px;
        padding: 10px;
        font-weight: 600;
        border-radius: 2px;
        @include for-smallest{
            width: 100px;
            height: 50px;
        }
    }

    @-webkit-keyframes shimmer {
        0% {
            background-position: 100% 0
        }
        100% {
            background-position: -100% 0
        }
    }
    
    @keyframes shimmer {
        0% {
            background-position: 100% 0
        }
        100% {
            background-position: -100% 0
        }
    }
}