.s-main{
    display: flex;
    flex-direction: column;

    background: white;
    

    &__title{
        font-weight: 700;
        font-family: $primary-font;
        font-size: 38px;
   
        color: $background-dark-blue;
        @include for-mobile{
            font-family: inherit !important;
        }
        @include for-smallest{
            font-size: 20px;
        }
    }

    &__paragraph{
        font-family: $primary-font;
        font-weight: 400;
        font-size: 15px;
        line-height: 20.7px;
        margin-top: 29px;
        color: $background-dark-blue;
        @include for-mobile{
            font-family: inherit;
        }

        @include for-smallest{
            font-size: 14px !important;
        }
    }

    &__btn{
        border-radius: 2px;
        background: $background-blue;
        color: white;
        font-family: $primary-font;
        font-size: 32px;
        margin-top: 3%;

        @include for-desktop{
            left: 48px;
            padding: 10px;
            padding-top: 10px;
            padding-top: 10px;
        }

        @include for-mobile{
            margin-top: 28%;
        }
        @include for-smallest{
            margin-bottom: 77px !important;
            font-size: 25px;
        }
    }
}

