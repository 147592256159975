.s-banner{
    background: $background-dark-blue;
    
    &__title{
       color: white;
       letter-spacing: 4px;
font-weight: 
600;
font-family: $primary-font;
@include for-mobile{
    font-family: inherit !important;
}


    }

    @include for-smallest{
        font-size: 13px;
    }
}