.s-footer{
    position: absolute;
    bottom: 0;
    width: 100%;

    &__literatur{
        @include for-mobile{
            .custom-width{
                width: 100% !important;
            }
            position:  relative !important;
            width: 100% !important;
        }
    }
  
&__finalScreen{
  
    width: 100% !important;
    
}
  &__mobileFinal{
      @include for-mobile{
        width: 100% !important;
      }
  }
&__clipboard{
    border: 1px solid #2860F6;
    color: #2860F6 !important;
    padding: 10px;
    background: unset !important;
    font-weight: 600;
    border-radius: 2px;
    height: 64px;

    width: 200px;

    @include for-mobile{
        width: 100%;
        margin-bottom: 10px;
    }
    }

    @media(max-height: 640px){
        bottom: -109px;
    }

    @include for-desktop{
        left: 0;
        > div{
            // width: 93%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__shareBtn {
       
       
        &--desktop{
            img {
                max-width: 20px;
            }
           
            @include for-desktop{
                display: block;

                
                
            }

            @include for-mobile{
                display: none;
        
            }
        }
        &--mobile{
            img {
                max-width: 20px;
            }
        }
    }
}

.ReactModal__Overlay--after-open{
    background-color: rgba(70,70,70, 0.55) !important
}
.ReactModal__Overlay {
    background-color: rgba(70,70,70, 0.55) !important
}

.closeIcon{
    top: -23px !important;
    cursor: pointer;
        right: -20px !important; 
    @include for-mobile{
        top: -8px !important;
        right: -5px!important;
    }
}

.footer-home{
    @include for-mobile{
        bottom: 0 !important;
        left: 0;
        right: 0;
        position: absolute
    }
}

.ReactModal__Content{
    @include for-mobile{
        padding: 22px !important;
    }
 
} 

.ReactModal__Content--after-open{
    @include for-mobile{
        padding: 22px !important;
        width: 90% !important;
        border-radius: 30px !important;
    }
}


.react-share__ShareButton{
    width: auto !important;
}